
<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";


// import {
//   required 
// } from "vuelidate/lib/validators";


/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Compliance Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
   Multiselect,
    Layout,
    PageHeader,
    DatePicker,
    
  },
  data() {
    return {
      title: "Compliance Report",
      items: [
        {
          text: "Compliance Report",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],


  
    restDetails:[],
    documentsArr:[],

    };
  },

    validations: {
   


  },
  methods:{

    getComplianceReportData(){
         this.axios
        .post(this.$loggedRole+"/getComplianceReportData", {restBranchID:sessionStorage.getItem('restBranchID')}
        )
        .then((response) => {
            this.restDetails = response.data.data;
            this.documentsArr = response.data.documentsArr;
      
          
        });
    },
   

  },
     mounted(){
       
        if(this.$route.params.id){
           sessionStorage.setItem('restBranchID',this.$route.params.id);
        }
        this.getComplianceReportData();

  },

  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
  
     <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                   <img v-if="restDetails.thumbnail" :src="restDetails.thumbnail" class="profile-logo mb-2"  alt="thumb" style="width:auto; height:60px;">
                     <div class="table-responsive col-md-8">
                                    <table class="table table-striped mb-0" style="margin-top:15px;border: 1px solid #f0f0f0;">
                                       
                                        <tbody>
                                             <tr>
                                               <td>Restaurant Name</td>
                                                <td>{{restDetails.name}}</td>
                                            
                                            </tr>
                                            <tr>
                                               <td>Address</td>
                                                <td style="white-space: unset;">{{restDetails.address}}</td>
                                            
                                            </tr>
                                             <tr>
                                               <td>Website</td>
                                                <td>
                                                    <a  v-bind:href="restDetails.website" target="_blank" v-if="restDetails.website">
                                                    {{restDetails.website}}</a>
                                                    <span v-else> - </span>
                                                </td>
                                            
                                            </tr>
                                            <tr>
                                               <td>GST No</td>
                                                <td>{{restDetails.gstNO}}</td>
                                            
                                            </tr>
                                          
                                           
                                        </tbody>
                                    </table>
                              </div>
                             </div>
               
            </div>
        </div>
    <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                            <h6 class="mb-1"> Document List </h6>

                             <div class="table-responsive col-md-8">
                                    <table class="table table-striped mb-0" style="margin-top:15px;border: 1px solid #f0f0f0;">
                                        <thead style="font-weight: 500;">
                                          <tr>
                                          <td>Document Name</td>
                                          <td>Document Number</td>
                                          <td>Date of Expiry</td>
                                          <td>Download</td>
                                          </tr>
                                        </thead>
                                        
                                        <tbody v-for="(data, index) in documentsArr" :key="data.id">
                                            <tr>
                                              
                                                <td>{{data.documentName}}</td>
                                          
                                                <td>
                                                  <span v-if="data.licenseNO">{{data.licenseNO}}</span>
                                                  <span v-else> - </span>
                                                  </td>
                                                <td>
                                                   <span v-if="data.licenseExpiryDate">{{data.licenseExpiryDate}}</span>
                                                  <span v-else> - </span>
                                                </td>
                                                <td>
                                                  <a v-bind:href="data.licenseDoc" v-if="data.licenseDoc!=''" download>
                                                    <i v-if="data.licenseDoc" class="uil uil-download-alt"></i>
                                                  </a>  
                                                     <span v-else> - </span>   
                                                </td> 
                                            </tr>
                                        </tbody>
                                    </table>
                             </div>
             
                </div>
               
            </div>
        </div>
      

  

</Layout>
</template>

<style scoped>
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


